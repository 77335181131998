
<template>
    <div>
        <v-btn @click="quickAddPoi = true"><v-icon>visibility</v-icon></v-btn>
        <v-dialog v-model="quickAddPoi" width="60vw">
            <v-card height="80vh">
                <PoiQuickAdd/>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import PoiQuickAdd from '../../POI/PoiQuickAdd.vue'
export default {
    components: {
        PoiQuickAdd
    },
    data: () => ({
        quickAddPoi: false,
        isGreen: false,
    }),
    methods: {
        addPoi() {
            this.quickAddPoi = false;
            this.$emit('addPoi');
        }
    }
}
</script>

<style scoped>

.buttons {
    background-color: #a53b3b !important;
}

</style>
